import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const VPSVideo = lazy(() => import('sections/hero/VPSVideo.js'));
const BotonCloud = lazy(() => import('sections/services/BotonCloud.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const VPSsinA = lazy(() => import('sections/about/VPSsinA.js'));
const BoxCol2 = lazy(() => import('sections/hero/BoxCol2.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));


class Index extends React.Component {

  render() {

    

    return (
      <div>
        <Helmet>
          <title>Servidores VPS en RAID 10 | Colombia, Pereira, Bogotá, Medellín</title>
          <meta name="description" content="Descubre la potencia de los Servidores VPS en RAID 10 en Pereira, Bogotá, Medellín, Colombia. Rendimiento robusto y confiable con recursos dedicados." />
        </Helmet>
        <Layout>
          <VPSVideo />
          <VPSsinA />
          <BoxCol2 />          
          <Llamenos />
          <BotonCloud />          
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`